<script>
import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import { ArrowUpIcon } from "vue-feather-icons";
import ApiService from "@/services/api.service";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      files: null,
      tekuns: [],
      selectedTransaction: {},
      date: null,
      endDate: null,
      checkDate: null,
      status: 0,
      wasiat_cert: null,
      searchData: "",
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
      total: {
        pending: 0,
        verified: 0,
        sum: 0,
        sumPending: 0,
        sumVerified: 0,
        count_exist: 0,
        count_not_exist: 0,
      },
      responseDate: {
        start: null,
        end: null,
      },
    };
  },
  components: {
    Navbar,
    ArrowUpIcon,
    Footer,
  },
  mounted() {
    if (this.$route.query.date) {
      this.date = this.$route.query.date;
      this.status = this.$route.query.status;
    } else {
      this.date = moment().format("YYYY-MM-DD");
      this.endDate = moment().add(1, "day").format("YYYY-MM-DD");
    }

    this.getTekunsList();
  },
  watch: {
    date: {
      handler: function (val) {
        // this.endDate = moment(val).add(1, "day").format("YYYY-MM-DD");
      },
    },
  },
  methods: {
    getTekunsList() {
      var query;

      if (this.userRole == "Admin") {
        query = `tekun/wasiats?load_with=wasiat,agent,attachment&agent_id=${this.currentUser.id}&status=${this.status}&limit=${this.pagination.perPage}&page=${this.pagination.currentPage}`;
      } else {
        query = `tekun/wasiats?load_with=wasiat,agent,attachment&status=${this.status}&limit=${this.pagination.perPage}&page=${this.pagination.currentPage}`;
      }
      ApiService.get(query).then((response) => {
        console.log("Tekun list", response.data.tekuns.data);
        let temp_array = response.data.tekuns.data ?? [];
        this.pagination.totalRows = response.data.tekuns.total;
        var newobj = {};

        var count;
        if (this.pagination.currentPage == 1) {
          count = this.pagination.currentPage;
        } else {
          count =
            this.pagination.currentPage * this.pagination.perPage -
            this.pagination.perPage +
            1;
        }
        console.log("count", count);
        for (const index in temp_array) {
          newobj[count] = Object.assign({}, temp_array[index]);
          count++;
        }
        this.tekuns = newobj;
      });
    },
    pageClicks(page) {
      this.pagination.currentPage = page;
      this.getTekunsList();
    },
    changePerPage() {
      this.pagination.currentPage = 1;
      this.getTekunsList();
    },
    search(status) {
      this.pagination.currentPage = 1;
      if (status != null) {
        this.status = status;
      }
      this.getTekunsList();
    },
    async emailClientCreate(userId) {
      return await ApiService.get(`user/${userId}/email-client-created`)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          return false;
        });
    },
    async setVerified(tekun) {
      this.$swal.fire({
        icon: "warning",
        html: this.$t("approval-cert"),
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: this.$t("approve"),
        cancelButtonText: this.$t("cancel"),
        confirmButtonColor: "#3085d6",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {
          return await ApiService.post(`tekun/${tekun.id}/wasiat`, {
            _method: "PATCH",
            status: 1,
            verified_by_id: this.currentUser.id,
          }).then((response) => {
            this.$swal.fire({
              icon: "success",
              html: this.$t("upload-cert-success-confirmation"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("close"),
            });
            this.emailClientCreate(tekun.wasiat.user_id);
            this.getTekunsList();
          });
        },
      });
    },
    async deleteUploadCert(tekun) {
      this.$swal.fire({
        icon: "warning",
        html: this.$t("delete-cert"),
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: this.$t("remove"),
        cancelButtonText: this.$t("cancel"),
        confirmButtonColor: "#e43f52",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {
          return await ApiService.post(`tekun/${tekun.id}/delete`, {
            _method: "DELETE",
          }).then((response) => {
            this.$swal.fire({
              icon: "success",
              html: this.$t("delete-cert-success"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("close"),
            });
            this.getTekunsList();
          });
        },
      });
    },
    showUploadModal() {
      this.$refs["upload-modal"].show();
    },
    hideUploadModal() {
      this.$refs["upload-modal"].hide();
    },
    onFileChange() {
      this.files = this.$refs.wasiat_cert.files;
      console.log("files", this.files);
    },
    async onSubmitCerts() {
      let formData = new FormData();

      formData.append("cert_approved_at", this.date);
      if (this.files.length <= 1) {
        formData.append("files[0]", this.files[0]);
      } else {
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          formData.append("files[" + i + "]", file);
        }
      }

      return ApiService.post(`tekun/document`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.$swal.fire({
            icon: "success",
            html: this.$t("upload-doc-success"),
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: this.$t("close"),
          });
          this.hideUploadModal();
          this.getTekunsList();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    clickEdit(item) {
      this.selectedTransaction = item;
      this.$refs["edit-modal"].show();
    },
    hideEditModal() {
      this.$refs["edit-modal"].hide();
    },
    showCert(path) {
      var url = path;
      if (url) {
      window.open(url, "_blank");
      }
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toHumanDate: function (value) {
      return moment(value).format("D MMMM YYYY");
    },
    toHumanDateTime: function (value) {
      if (value) {
        return moment(value).format("D MMM YY, HH:mm:ss");
      }
      return "";
    },
    getConvertDate: function (tempDate) {
      if (!tempDate) return "-";
      var date = new Date(tempDate);
      var dateConvert =
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        date.getFullYear();
      return dateConvert;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userRole", "userAccess"]),
    orgCode() {
      return this.userAccess?.organization?.code;
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4
                v-if="this.userAccess.role == 'Staff'"
                class="title text-white"
                style="text-transform: capitalize"
              >
                {{ $t("tekun.cert-verification") }}
              </h4>
              <h4
                v-else
                class="title text-white"
                style="text-transform: capitalize"
              >
                {{ $t("tekun.cert-upload") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div
        class="
          container
          card
          public-profile
          border-0
          rounded
          shadow
          overflow-hidden
        "
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label">{{
                  $t("status")
                }}</label>
                <div class="col-sm-8 col-md-8">
                  <select
                    name=""
                    id=""
                    v-model="status"
                    @change="search(status)"
                    class="form-control"
                  >
                    <option value="0">{{ $t("all") }}</option>
                    <option value="null">{{ $t("pending") }}</option>
                    <option value="1">{{ $t("passed") }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6 text-right">
              <button @click="showUploadModal" class="btn btn-primary">
                {{ $t("cert-upload") }}
              </button>
              <!-- &nbsp;
              <button @click="getExcel" class="btn btn-primary">
                {{ $t("get-excel") }}
              </button>
              &nbsp;
              <button @click="search(null)" class="btn btn-primary">
                Carian
              </button> -->
            </div>
          </div>
          <hr />

          <div class="table-responsive bg-white shadow rounded mt-3">
            <table
              class="table mb-0 table-center table-bordered"
              style="font-size: 12px"
            >
              <thead class="bg-light">
                <tr>
                  <th rowspan="2">{{ $t("index") }}</th>
                  <th rowspan="2">{{ $t("tekun.cert-name") }}</th>
                  <th rowspan="2" class="text-center">
                    {{ $t("uploaded-by") }}
                  </th>
                  <th rowspan="2">{{ $t("uploaded-date") }}</th>
                  <th rowspan="2">{{ $t("cert-approved-date") }}</th>
                  <th rowspan="2">{{ $t("verification-status") }}</th>
                  <th rowspan="2">{{ $t("verification-date") }}</th>
                  <th rowspan="2">{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="tekuns && tekuns.length == 0">
                  <td class="text-center" colspan="14">
                    <h6 class="mt-5 mb-5">{{ $t("no-data") }}</h6>
                  </td>
                </tr>
                <tr v-for="(tekun, index) in tekuns" :key="index">
                  <td>
                    {{ index }}
                  </td>
                  <td>{{ tekun.attachment.original_filename }}</td>
                  <td>
                    {{ tekun.agent.name }}
                  </td>
                  <td>
                    {{ tekun.created_at | getConvertDate }}
                  </td>
                  <td>
                    {{ tekun.cert_approved_at | getConvertDate }}
                  </td>
                  <td>
                    <span
                      v-if="tekun.status == 1"
                      class="badge badge-success"
                      >{{ $t("passed") }}</span
                    >
                    <span
                      v-if="tekun.status == 0"
                      class="badge badge-warning"
                      >{{ $t("pending") }}</span
                    >
                  </td>
                  <td>
                    {{ tekun.attachment_verified_at | getConvertDate }}
                  </td>
                  <td class="text-center">
                    <div
                      class="btn-group-vertical"
                      role="group"
                      aria-label="Vertical button group"
                    >
                      <b-button
                        class="btn btn-sm btn-secondary"
                        @click="showCert(tekun.attachment.public_path)"
                      >
                        {{ $t("cert-view") }}
                      </b-button>
                      <b-button
                        v-if="userRole == 'Staff' && tekun.status == 0"
                        class="btn btn-success btn-sm"
                        @click="setVerified(tekun)"
                      >
                        {{ $t("passed") }}
                      </b-button>
                      <b-button
                        v-if="userRole == 'Staff' && tekun.status == 0"
                        class="btn btn-danger btn-sm"
                        @click="deleteUploadCert(tekun)"
                      >
                        {{ $t("remove") }}
                      </b-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Upload Verifikasi Modal -->
          <b-modal
            :no-enforce-focus="true"
            ref="upload-modal"
            id="upload-modal"
            hide-footer
            centered
            title="Muat Naik Sijil Wasiat"
          >
            <form id="edit-form" @submit.prevent="onSubmitCerts">
              <div class="form-group">
                <label for="InputName">{{ $t("cert-upload") }}</label
                ><br />
                <em
                  ><small class="text-muted">{{ $t("tekun-info1") }}</small></em
                >
                <input
                  type="file"
                  name="wasiat_cert"
                  ref="wasiat_cert"
                  class="mt-2 form-control form-control-file"
                  multiple="multiple"
                  accept="application/pdf"
                  v-on:change="onFileChange"
                />
              </div>
              <div class="form-group">
                <label>{{ $t("cert-approved-date") }}</label>
                <br />
                <em
                  ><small class="text-muted">{{ $t("tekun-info2") }}</small></em
                >
                <input
                  type="date"
                  class="form-control"
                  name=""
                  id=""
                  v-model="date"
                />
              </div>

              <b-button class="mt-5" variant="primary" type="submit" block>{{
                $t("upload")
              }}</b-button>
              <b-button
                class="mt-3"
                variant="outline-secondary"
                block
                @click="hideUploadModal"
                >Tutup</b-button
              >
            </form>
          </b-modal>

          <!-- Pagination -->
          <div class="row">
            <div class="col-lg-9 col-md-7">
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.perPage"
                @change="pageClicks"
                aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="form custom-form">
                <div class="form-group mb-0">
                  <select
                    v-model="pagination.perPage"
                    class="form-control custom-select"
                    id="Sortbylist-job"
                    @change="changePerPage()"
                  >
                    <option value="5">5 {{ $t("per-page") }}</option>
                    <option value="10">10 {{ $t("per-page") }}</option>
                    <option value="25">25 {{ $t("per-page") }}</option>
                    <option value="50">50 {{ $t("per-page") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
